import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useTranslation, initReactI18next } from 'react-i18next';
import i18n from 'i18next';
import { useLocation, useNavigate } from 'react-router-dom';

// Translation resources
const resources = {
  en: {
    translation: {
      trackingInformation: "Tracking Information",
      status: "Status",
      location: "Location",
      history: "History",
      date: "Date",
      event: "Event",
      showMore: "Show More",
      showLess: "Show Less",
      back: "Back",
      enterTrackingNumber: "Enter your tracking number",
      track: "Track",
      errorMessage: "Unable to fetch tracking information.",
      noStatusDetails: "No status details available.",
      selectLanguage: "Select Language"
    }
  },
  es: {
    translation: {
      trackingInformation: "Información de seguimiento",
      status: "Estado",
      location: "Ubicación",
      history: "Historial",
      date: "Fecha",
      event: "Evento",
      showMore: "Mostrar más",
      showLess: "Mostrar menos",
      back: "Volver",
      enterTrackingNumber: "Ingrese su número de seguimiento",
      track: "Rastrear",
      errorMessage: "No se puede obtener información de seguimiento.",
      noStatusDetails: "No hay detalles de estado disponibles.",
      selectLanguage: "Seleccione el idioma"
    }
  },
  zh: {
    translation: {
      trackingInformation: "追踪信息",
      status: "状态",
      location: "地点",
      history: "历史记录",
      date: "日期",
      event: "事件",
      showMore: "显示更多",
      showLess: "显示更少",
      back: "返回",
      enterTrackingNumber: "输入您的追踪号码",
      track: "追踪",
      errorMessage: "无法获取追踪信息。",
      noStatusDetails: "没有可用的状态详情。",
      selectLanguage: "选择语言"
    }
  }
};

i18n.use(initReactI18next).init({
  resources,
  lng: "en",
  fallbackLng: "en",
  interpolation: {
    escapeValue: false
  }
});

const TrackPackage = () => {
  const { t, i18n } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [trackingNumber, setTrackingNumber] = useState('');
  const [trackingInfo, setTrackingInfo] = useState(null);
  const [error, setError] = useState('');
  const [isTracking, setIsTracking] = useState(false);
  const [showFullHistory, setShowFullHistory] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const trackingNum = params.get('trackingNumber');
    if (trackingNum) {
      setTrackingNumber(trackingNum);
      setIsTracking(true);
      fetchTrackingInfo(trackingNum);
    }
  }, [location.search]);

  const fetchTrackingInfo = async (trackingNum = trackingNumber) => {
    if (!trackingNum) {
      setError('Please enter a tracking number.');
      return;
    }

    try {
      const response = await axios.post('https://backendtrack.gibtraders.com/track', {
        trackingNumber: trackingNum,
        carrierCode: 'FDXE',
      });

      const translatedData = await translateTrackingInfo(response.data.output.completeTrackResults[0].trackResults[0]);
      setTrackingInfo(translatedData);
      setError('');
    } catch (err) {
      setError(t('errorMessage'));
      console.error('Error:', err.response ? err.response.data : err.message);
    }
  };

  useEffect(() => {
    let interval;
    if (isTracking) {
      interval = setInterval(fetchTrackingInfo, 60000); // Poll every 60 seconds
    }
    return () => clearInterval(interval);
  }, [isTracking]);

  const handleTrack = () => {
    setIsTracking(true);
    navigate(`?trackingNumber=${trackingNumber}`);
    fetchTrackingInfo();
  };

  const handleBack = () => {
    setIsTracking(false);
    setTrackingInfo(null);
    setTrackingNumber('');
    setError('');
    navigate('');
  };

  const toggleShowFullHistory = () => {
    setShowFullHistory(prev => !prev);
  };

  const translateTrackingInfo = async (data) => {
    // Mock translation function. Replace with actual translation logic if needed.
    return data;
  };

  const renderTrackingInfo = () => {
    if (!trackingInfo) return null;

    const { latestStatusDetail, scanEvents } = trackingInfo;
    if (!latestStatusDetail || !scanEvents || scanEvents.length === 0) {
      return <p>{t('noStatusDetails')}</p>;
    }

    const eventsToShow = showFullHistory ? scanEvents : scanEvents.slice(0, 4);

    return (
      <div className="tracking-info">
        <h2>{t('trackingInformation')}</h2>
        <ul className="timeline">
          {eventsToShow.map((event, index) => (
            <li key={index} className={`timeline-item ${index === 0 ? 'active' : ''}`}>
              <div className="timeline-content">
                <p><strong>{t('status')}:</strong> {event.eventDescription}</p>
                <p><strong>{t('location')}:</strong> {event.scanLocation.city}, {event.scanLocation.stateOrProvinceCode}</p>
                <time><strong>{t('date')}:</strong> {new Date(event.date).toLocaleString()}</time>
              </div>
            </li>
          ))}
        </ul>
        <div className="button-container">
          <button className="modern-button" onClick={toggleShowFullHistory}>
            {showFullHistory ? t('showLess') : t('showMore')}
          </button>
          <button className="modern-button" onClick={handleBack}>{t('back')}</button>
        </div>
      </div>
    );
  };

  const renderInputForm = () => {
    return (
      <div className="input-form">
        <input
          type="text"
          placeholder={t('enterTrackingNumber')}
          value={trackingNumber}
          onChange={(e) => setTrackingNumber(e.target.value)}
        />
        <button className="modern-button" onClick={handleTrack}>{t('track')}</button>
        {error && <p className="error">{error}</p>}
      </div>
    );
  };

  return (
    <div className="tracker-container">
      <div className="header">GlobusCargo</div>
      <div className="language-select">
        <label>{t('selectLanguage')}:</label>
        <div className="dropdown">
          <button className="dropdown-button">{i18n.language.toUpperCase()}</button>
          <div className="dropdown-content">
            <a onClick={() => i18n.changeLanguage('en')}>English</a>
            <a onClick={() => i18n.changeLanguage('es')}>Español</a>
            <a onClick={() => i18n.changeLanguage('zh')}>中文</a>
          </div>
        </div>
      </div>
      {trackingInfo ? renderTrackingInfo() : renderInputForm()}
    </div>
  );
};

export default TrackPackage;
